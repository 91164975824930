<template>
  <c-flex flex-dir="column">
    <c-flex my="10px" align-items="center" justify="space-between">
      <c-text mt="10px" font-size="16px" font-weight="700" color="#333">
        Kondisi tubuh
      </c-text>
      <c-button
        v-if="isNutri"
        variant-color="brand"
        border-radius="50px"
        as="router-link"
        :to="{ name: 'nutri.body-composition-edit' }"
      >
        <c-image
          mr="10px"
          :src="require('@/assets/icon-pencil.svg')"
          alt="icon"
        />
        Edit
      </c-button>
    </c-flex>
    <table mt="10px" v-chakra>
      <thead>
        <tr>
          <th>Sekarang</th>
          <th>Standard</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isEmpty" v-chakra w="100%" bg="none">
          <td colspan="3" class="no-background" bg="none">
            <EmptyState title="Kondisi tubuh belum terisi" />
          </td>
        </tr>
        <template v-else v-for="data in kondisiTubuhMapped">
          <tr :key="`title-${data.title}`">
            <td></td>
            <td>{{ data.title }}</td>
            <td>&nbsp;</td>
          </tr>
          <tr :key="`point-${data.title}`">
            <td>{{ data.pointSekarang }}</td>
            <td>{{ data.pointStandard }}</td>
            <td>{{ data.conclusion | toConclusion }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </c-flex>
</template>

<script>
import { mapState } from "vuex";
import EmptyState from "./empty-state.vue";
export default {
  name: "CardKondisiTubuh",
  props: ["kondisiTubuh"],
  components: { EmptyState },
  updated() {
    console.log("this.kondisiTubuh", this.kondisiTubuh);
  },
  computed: {
    // ...mapState({
    //   kondisiTubuh(s) {
    //     return this.isNutri ? s.mealPlan.kondisiTubuh : s.clients.kondisiTubuh;
    //   },
    // }),
    isEmpty() {
      return Object.keys(this.kondisiTubuh ?? {}).length === 0;
    },
    isNutri() {
      return (
        this.$route.matched
          .map((it) => it.meta)
          .reduce((res, it) => ({ ...res, ...it }), {}).isNutritionist ?? false
      );
    },
    kondisiTubuhMapped() {
      let k = this.kondisiTubuh;
      return [
        {
          title: "Cairan Tubuh",
          pointSekarang: `${k?.fluidIntakeCondition} gelas/hari`,
          pointStandard: `${k?.fluidIntakeStandard} gelas/hari`,
          conclusion: k?.fluidIntakeConclusion,
        },
        {
          title: "Aktivitas Fisik",
          pointSekarang: `${k?.physicalActivityCondition} menit/minggu`,
          pointStandard: `${k?.physicalActivityStandard} menit/minggu`,
          conclusion: k?.physicalActivityConclusion,
        },
        {
          title: "Lingkar Pinggang",
          pointSekarang: `${k?.waistSize} cm`,
          pointStandard: `${k?.minimumStandardWaistSize} / ${k?.maximumStandardWaistSize} cm`,
          conclusion: k?.waistSizeConclusion,
        },
      ];
    },
  },
  filters: {
    toConclusion(value) {
      switch (value) {
        case "excessive":
          return "Berlebih";
        case "adequate":
          return "Cukup";
        default:
          return "Kurang";
      }
    },
  },
};
</script>

<style scoped>
thead {
  border-bottom: 1px solid #e0e0e0;
  height: 34px;
  padding: 8px;
}
thead tr th {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #888888;
}
tbody tr td {
  font-weight: 400;
  text-align: center;
  height: 32px;
}
tbody tr:nth-child(odd) td:not(.no-background) {
  font-size: 16px;
  line-height: 24px;
  color: #111;
  background: #f2f9f9;
  border-radius: 4px;
}
tbody tr:nth-child(even) td {
  font-size: 14px;
  line-height: 21px;
  color: #555;
}
</style>
