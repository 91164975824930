<template>
  <c-flex w="100%" flex-dir="column" justify-content="center" align-items="center" bg="#F2F2F2" border-radius="12px" my="10px">
    <c-image
      w="142px"
      h="148px"
      m="24px"
      :src="require('@/assets/img-meal-plan-empty-illustration.svg')"
    />
    <c-text
      mt="30px"
      font-size="16px"
      line-height="150%"
      text-align="center"
      color="#555555"
      my="20px"
    >
      {{ title }}
    </c-text>
  </c-flex>
</template>

<script>
export default {
  name: "empty-state",
  props: { title: String },
};
</script>

<style scoped></style>
