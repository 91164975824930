<template>
  <c-flex flex-dir="column">
    <c-flex my="10px" align-items="center" justify="space-between">
      <c-text mt="10px" font-size="16px" font-weight="700" color="#333">
        Saran-saran
      </c-text>
      <c-button
        v-if="this['auth::role'] === 'nutritionist'"
        variant-color="brand"
        border-radius="50px"
        @click.prevent="$emit('edit')"
      >
        <c-image
          mr="10px"
          :src="require('@/assets/icon-pencil.svg')"
          alt="icon"
        />
        Edit
      </c-button>
    </c-flex>

    <c-text white-space="pre-line">
      {{ saran.advice }}
    </c-text>
  </c-flex>
</template>

<script>
export default {
  name: "CardSaran",
  props: ["saran"],
};
</script>
