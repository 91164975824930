<template>
  <c-box>
    <c-flex
      v-for="(menu, index) in menus"
      :key="index"
      w="100%"
      align-items="flex-start"
      p="10px"
      :mt="index !== 0 ? ['20px'] : []"
      bg="white"
      box-shadow="0px 0px 8px rgba(0, 0, 0, 0.1)"
      border-radius="8px"
    >
      <c-flex flex="1" flex-dir="column">
        <c-flex flex="1" align-items="center">
          <c-text
            font-size="16px"
            font-style="normal"
            font-weight="700"
            line-height="24px"
          >
            {{ menu.title }}
          </c-text>
          <c-flex ml="10px" align-items="center">
            <c-image
              h="24px"
              w="24px"
              :src="require('@/assets/icon-jam-grey.svg')"
              alt="icon waktu"
            />
            <c-text
              ml="5px"
              font-size="14px"
              font-weight="400"
              line-height="21px"
            >
              {{ menu.time }}
            </c-text>
          </c-flex>
        </c-flex>

        <c-list v-if="menu.meals.length > 0" style-type="disc">
          <c-list-item v-for="(item, index) in menu.meals" :key="index">
            {{ item.text }}
          </c-list-item>
        </c-list>
      </c-flex>
      <FlatButton
        v-if="withSelectMenuBtn"
        variant="ghost"
        h="auto"
        w="auto"
        min-w="auto"
        m="0"
        p="0"
        @click="$emit('select-menu', menu)"
      >
        <c-image
          h="24px"
          w="24px"
          :src="require('@/assets/icon-chevron-right.svg')"
          alt="icon arrow"
        />
      </FlatButton>
    </c-flex>
  </c-box>
</template>

<script>
import FlatButton from "@/components/flat-button.vue";

export default {
  name: "CardMenu",
  props: ["menus"],
  components: {
    FlatButton,
  },
  computed: {
    withSelectMenuBtn() {
      let handler = this.$listeners?.selectMenu ?? this.$listeners?.['select-menu']
      return handler != null
    },
  },
};
</script>

<style scoped></style>
