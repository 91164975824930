import { render, staticRenderFns } from "./card-kondisi-tubuh.vue?vue&type=template&id=4686f53c&scoped=true&"
import script from "./card-kondisi-tubuh.vue?vue&type=script&lang=js&"
export * from "./card-kondisi-tubuh.vue?vue&type=script&lang=js&"
import style0 from "./card-kondisi-tubuh.vue?vue&type=style&index=0&id=4686f53c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4686f53c",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex})
