<template>
  <c-button
    variant="ghost"
    m="5px"
    min-w="auto"
    flex-dir="column"
    h="60px"
    w="40px"
    border-radius="50px"
    p="3px"
    :bg="selected ? ['brand.900'] : []"
    :_hover="{
      bg: 'brand.900',
      '& p': {
        color: 'white'
      },
    }"
    align-items="center"
    justify-content="space-between"
    @click="$emit('click', week)"
  >
    <c-text
      font-size="10px"
      font-weight="400"
      line-height="15px"
      text-align="center"
      :color="selected ? ['white'] : ['#888888']"
      mt="2px"
    >
      Hari
    </c-text>
    <c-flex
      align-items="center"
      justify-content="center"
      :color="selected ? ['brand.900'] : ['#111111']"
      bg="white"
      border-radius="50px"
      font-size="14px"
      font-weight="700"
      line-height="21px"
      text-align="center"
      h="32px"
      w="32px"
      pos="relative"
    >
      <c-box
        v-if="dotted"
        pos="absolute"
        h="8px"
        w="8px"
        bg="red.900"
        rounded="full"
        top="0"
        right="0"
      />
      {{ week }}
    </c-flex>
  </c-button>
</template>

<script>
export default {
  name: "BadgeWeek",
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    week: {
      type: Number,
      required: true,
    },
    dotted: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped></style>
