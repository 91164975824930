<template>
  <c-flex flex-dir="column" v-chakra="{ gap: '8px' }">
    <c-text font-size="16px" font-weight="400">
      {{ title }}
    </c-text>

    <c-flex flex-dir="column">
      <c-flex align-items="center">
        <c-text
          flex-basis="66px"
          font-size="12px"
          font-weight="400"
          color="#555"
        >
          Sekarang
        </c-text>
        <c-flex
          align-items="center"
          border-left="1px solid #000"
          :w="`${pointSekarangWidth}px`"
          h="30px"
          py="3px"
        >
          <c-box
            h="20px"
            w="100%"
            bg="#F4CC46"
            border-top-right-radius="4px"
            border-bottom-right-radius="4px"
          />
        </c-flex>
        <c-text ml="5px" font-size="12px" font-weight="400">
          {{ pointSekarang | toFixed }} {{ satuan }}
        </c-text>
      </c-flex>
      <c-flex align-items="center">
        <c-text
          flex-basis="66px"
          font-size="12px"
          font-weight="400"
          color="#555"
        >
          Kebutuhan
        </c-text>
        <c-flex
          align-items="center"
          border-left="1px solid #000"
          :w="`${pointKebutuhanWidth}px`"
          h="30px"
        >
          <c-box
            h="20px"
            w="100%"
            bg="#00A68C"
            border-top-right-radius="4px"
            border-bottom-right-radius="4px"
          />
        </c-flex>
        <c-text ml="5px" font-size="12px" font-weight="400" color="#555">
          {{ pointKebutuhan | toFixed }} {{ satuan }}
        </c-text>
      </c-flex>
    </c-flex>

    <c-flex mt="10px" bg="#F2F2F2" p="8px" border-radius="4px">
      <c-image
        h="18px"
        w="18px"
        :src="require('@/assets/icon-pentung-grey.svg')"
        alt="icon alert"
      />
      <c-text font-size="12px" font-weight="400" color="#333">
        {{ warningText }}
      </c-text>
    </c-flex>
  </c-flex>
</template>

<script>
export default {
  name: "CardKalori",
  props: {
    title: String,
    pointSekarang: Number,
    pointKebutuhan: Number,
    warningText: String,
    satuan: String,
  },
  computed: {
    maxPercentageValue() {
      return Math.max(this.pointSekarang, this.pointKebutuhan)
    },
    pointSekarangWidth() {
      // 200 = pixel width / bar width
      return (this.pointSekarang / this.maxPercentageValue) * 200
    },
    pointKebutuhanWidth() {
      return (this.pointKebutuhan / this.maxPercentageValue) * 200
    },
  },
}
</script>

<style></style>
